//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 组件
import {
    List as AList,
    Avatar as AAvatar
} from 'ant-design-vue'
import CommonTitle from '@/components/CommonTitle'
export default {
    name: 'ServiceFeatures',
    components: {
        CommonTitle,
        AList,
        AListItem: AList.Item,
        AListItemMeta: AList.Item.Meta,
        AAvatar
    },
    data() {
        return {
            title: '更多保险服务',
            list: [
                {
                    name: '工程保证保险',
                    imgUrl: require('@/assets/images/home/service12x.png'),
                    children: [
                        {
                            name: '工程投标保证保险'
                        },
                        {
                            name: '工程履约保证保险'
                        },
                        {
                            name: '农民工工资支付保证保险'
                        },
                        {
                            name: '工程质量保证保险'
                        },
                        {
                            name: '工程款支付保证保险'
                        },
                        {
                            name: '工程预付款保证保险'
                        }
                    ]
                },
                {
                    name: '安全生产保险',
                    imgUrl: require('@/assets/images/home/service22x.png'),
                    children: [
                        {
                            name: '安全生产责任保险'
                        }, {
                            name: '雇主责任保险'
                        }, {
                            name: '建筑工程意外伤害保险'
                        }
                    ]
                },
                {},
                {}
            ],
            list1: [
                {
                    name: '工程质量保险',
                    imgUrl: require('@/assets/images/home/service32x.png'),
                    children: [
                        {
                            name: '工程质量保证保险'
                        }, {
                            name: 'IDI工程质量潜在缺陷保证保险'
                        }
                    ]
                }, {
                    name: '职业责任保险',
                    imgUrl: require('@/assets/images/home/service42x.png'),
                    children: [
                        {
                            name: '勘察责任保险'
                        },
                        {
                            name: '设计责任保险'
                        },
                        {
                            name: '监理责任保险'
                        },
                        {
                            name: '施工责任保险'
                        }
                    ]
                }
            ],
            list2: [
                {
                    name: '工程一切险',
                    imgUrl: require('@/assets/images/home/service52x.png'),
                    children: [
                        {
                            name: '建筑工程一切险及第三者责任险'
                        }, {
                            name: '安装工程一切险及第三者责任险'
                        }
                    ]
                }, {
                    name: '其他工程保险',
                    imgUrl: require('@/assets/images/home/service62x.png'),
                    children: [
                        {
                            name: '工程机械设备综合保险'
                        },
                        {
                            name: '货物运输保险'
                        },
                        {
                            name: '环境污染责任保险'
                        },
                        {
                            name: '巨灾保险'
                        }
                    ]
                }
            ]
        }
    }
}
