//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 组件
import Banner from '@/weights/Banner'
import NavigationBar from '@/weights/NavigationBar'
import ServicePanel from '@/weights/ServicePanel'
import AData from '@/weights/Home/Data'
import Insurance from '@/weights/Home/Insurance'
import InsureService from '@/weights/Home/InsureService'
import ButlerService from '@/weights/ButlerService'
import News from '@/weights/Home/News'
import Partner from '@/weights/Home/Partner'
import Feature from '@/weights/Home/Feature'
import BannerContainer from '@/components/BannerContainer'
import RightSide from '@/components/RightSide'

// 全局数据
import { CAROUSAL_INDEX, PRODUCT_CONSULTING, AGENT_CONSULTING } from '@/constant/enum'

export default {
    name: 'index',
    components: {
        Banner,
        NavigationBar,
        ServicePanel,
        AData,
        Insurance,
        InsureService,
        ButlerService,
        News,
        Partner,
        Feature,
        BannerContainer,
        RightSide
    },
    data() {
        return {
            PRODUCT_CONSULTING,
            AGENT_CONSULTING,
            CAROUSAL_INDEX,
            title: '一站式管家服务'
        }
    },
    created() {
    },
    mounted() {
        this.$store.commit('SET_BUSINESS_DETAILS', '首页预约')
        // this.$captureMessage('测试sentry-----')
    },
    methods: {},
    async asyncData() {}
}
