// 验证必填项函数,
export const validateRequireFunc = (fieldName = '', errorCallback) => (rule, value, callback) => {
    // 错误信息
    let errorMsg = `请输入${fieldName}`
    if (value === '') {
        // 执行错误回调方法
        errorCallback && errorCallback(errorMsg)
        callback(new Error(errorMsg))
    } else {
        callback()
    }
}
// 验证邮箱
export const validateEmailFunc = (rule, value, callback) => {
    const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入正确的邮箱地址`
        callback(new Error(errorMsg))
    }
}
// 验证统一社会信用代码
export const validateSocialCreditCode = (rule, value, callback) => {
    const reg = /^[A-Z0-9]{18}$/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入正确的统一社会信用代码`
        callback(new Error(errorMsg))
    }
}
// 验证手机号有效性
export const validatePhoneNumber = (rule, value, callback) => {
    const reg = /^1[3456789][0-9]{9}$/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入正确的手机号`
        callback(new Error(errorMsg))
    }
}

// 验证手机号/座机号有效性
export const validatePhoneNumberAndLandlineNumber = (rule, value, callback) => {
    const reg = /^(1[3456789][0-9]{9})|(0\d{2,3}-[1-9]\d{6,7})$/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        callback(new Error('请输入正确的号码格式'))
    }
}

// 验证密码强度
export const validatePassword = (rule, value, callback) => {
    const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,20}$/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入6~20位密码，必须包含数字、大小写字母`
        callback(new Error(errorMsg))
    }
}
// 验证url有效性
export const validateUrl = (rule, value, callback) => {
    const reg = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入有效的url地址`
        callback(new Error(errorMsg))
    }
}
