//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CommonTitle from '@/components/CommonTitle'
export default {
    name: 'ButlerService',
    components: {
        CommonTitle
    },
    data() {
        return {
            title: '管家服务',
            butlerList: [
                {
                    imgUrl: require('@/assets/images/quote/houseKeeper.png'),
                    title: '一站式管家服务',
                    describe: '您只需点击投保，一站式管家将为您贴身服务，从保险方案设计、协助投保以及更多后续工作，让您省心省力，再无后顾之忧。'
                }, {
                    imgUrl: require('@/assets/images/quote/major.png'),
                    title: '专业保险咨询顾问',
                    describe: '专业保险经纪人，丰富的工程类保险从业经验。'
                }, {
                    imgUrl: require('@/assets/images/quote/standardization.png'),
                    title: '标准化服务流程',
                    describe: '需求分析和方案定制，全面满足客户需求。'
                }
                // {
                //     imgUrl: require('@/assets/images/quote/service2x.png'),
                //     title: '服务记录可追溯',
                //     describe: '记录每一次工保服务，让保险服务更透明。',
                // }
            ]
        }
    }
}
