export function filterParams(obj) {
    var _newPar = {}
    for (var key in obj) {
        // 如果对象属性的值不为空，就保存该属性
        if ((obj[key] === 0 || obj[key] === false || obj[key]) && obj[key].toString().replace(/(^\s*)|(\s*$)/g, '') !== '') {
            _newPar[key] = obj[key]
        }
    }
    return _newPar
}
