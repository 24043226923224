//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 组件
import {
    Modal as AModal,
    Button as AButton
} from 'ant-design-vue'
import FormPanel from './FormPanel'
import CustomModal from '@/components/CustomModal'
import CustomButton from '@/components/CustomButton'

export default {
    name: 'BookingModal',
    components: {
        AModal,
        AButton,
        FormPanel,
        CustomModal,
        CustomButton
    },
    props: {
        visible: {
            type: Boolean,
            default() {
                return false
            }
        },
        mode: {
            type: String,
            default() {
                return ''
            }
        },
        data: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            loading: false,
            maskClosable: false
        }
    },
    computed: {
        title() {
            return '一站式管家服务'
        }
    },
    methods: {
    /**
    * 提交按钮事件
    */
        handleOk() {
            this.$refs.formPanel.handleSend()
        },
        /**
    * 重置按钮事件
    */
        handleReset() {
            this.$refs.formPanel.handleReset()
        },
        /**
    * 按钮loading
    */
        handleLoading(loading) {
            this.loading = loading
        },
        /**
    * 关闭弹框
    */
        handleCancel() {
            this.$emit('close')
        },
        handleSendSuccess() {
            this.$emit('success')
        }
    }
}
