//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    FormModel as AFormModel,
    Input as AInput,
    Select as ASelect,
    DatePicker as ADatePicker,
    Icon as AIcon,
    Cascader as ACascader
} from 'ant-design-vue'
import VerificationCode from './SendVerificationCode'
import HalfDayDatePicker from '@/weights/HalfDayDatePicker'
import moment from 'moment'

import { PRODUCT_CONSULTING } from '@/constant/enum'

import getInsuranceListService from '@/service/product/getInsuranceListService'
import createBookingService from '@/service/product/createBookingService'
import getSourceIdService from '@/service/servicePanel/getSourceIdService'
import { filterParams } from '@/utils/search.js'
import selectArea from '@/mixins/selectArea'

// 工具类
import { validatePhoneNumber } from '@/utils/validate'

export default {
    name: 'ServicePanel',
    components: {
        AFormModel,
        AInput,
        AFormModelItem: AFormModel.Item,
        ASelect,
        ADatePicker,
        ASelectOption: ASelect.Option,
        AIcon,
        VerificationCode,
        HalfDayDatePicker,
        ACascader
    },
    mixins: [selectArea],
    props: {
        title: {
            type: String,
            default: ''
        },
        type: {
            type: [Number, String]
        },
        active: {
            type: Boolean,
            default: false
        },
        mode: {
            type: [String]
        },
        productInfo: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            PRODUCT_CONSULTING,
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入您的称呼',
                        trigger: 'blur'
                    }
                ],
                mobile: [
                    {
                        required: true,
                        message: '请输入手机号',
                        trigger: 'blur'
                    }, {
                        validator: validatePhoneNumber,
                        trigger: 'blur'
                    }
                ],
                phoneCode: [
                    {
                        required: true,
                        message: '请输入手机验证码',
                        trigger: 'blur'
                    }
                ],
                dangerPlantedId: [
                    {
                        required: true,
                        message: '请选择险种',
                        trigger: 'blur'
                    }
                ],
                appointmentContactDateTime: [
                    {
                        required: true,
                        message: '请选择时间',
                        trigger: 'blur'
                    }
                ]
            },
            form: {
                name: '',
                mobile: '',
                appointmentContactDateTime: {
                    date: moment(),
                    subsection: moment().hour() >= 12 ? 1 : 0
                },
                dangerPlantedId: ''
            },
            insuranceList: [],
            potentialCustomerSourceId: '',
            // 判断是否提交成功 清空验证码倒计时
            isSuccess: false,
            // 验证码是否验证成功
            passCode: false,
            // 验证码报错信息
            errorMsg: '',
            loading: false
        }
    },
    computed: {
        agentUserId() {
            return this.$route?.query?.agentUserId || ''
        },
        userInfo() {
            return this.$store.state.userInfo
        },
        dangerPlantedCategoryId() {
            const { form, insuranceList } = this
            const { dangerPlantedId } = form
            let dangerPlantedCategoryId = {}
            if (dangerPlantedId) {
                insuranceList.forEach(item => {
                    if (item.id === dangerPlantedId.key) {
                        dangerPlantedCategoryId = {
                            key: item.dangerPlantedCategoryId,
                            label: item.dangerPlantedCategoryName
                        }
                    }
                })
                return dangerPlantedCategoryId
            }
        }
    },
    watch: {},
    mounted() {
        this.getInsuranceList()
        this.getSourceId()
    },
    methods: {
        onChange(value) {
        },
        /**
     * 立即预约
     */
        handleOrder() {
            const { type, mode, productInfo, agentUserId } = this
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    let params = this.formatParams()
                    if (type === PRODUCT_CONSULTING) {
                        if (mode === 'productBooking') {
                            // 产品详情页
                            if (productInfo) {
                                const { dangerPlantedCategoryId, dangerPlantedCategoryName, dangerPlantedId, dangerPlantedName } = productInfo
                                if (!(dangerPlantedCategoryId && dangerPlantedCategoryName && dangerPlantedId && dangerPlantedName)) {
                                    return
                                }
                                params = {
                                    ...params,
                                    ...productInfo
                                }
                                if (agentUserId) {
                                    params.agentUserId = agentUserId
                                }
                            }
                        }
                    }
                    this.createBooking(params)
                }
            })
        },
        /**
     * 格式化参数
     */
        formatParams() {
            const { form, potentialCustomerSourceId, type, dangerPlantedCategoryId } = this
            const { name, id } = this.userInfo || {}
            const { mobile, appointmentContactDateTime, dangerPlantedId, provinceCode, cityCode, areaCode, phoneCode } = form
            let params = {
                potentialCustomerSourceId,
                type,
                userId: id,
                userName: name,
                dangerPlantedId: dangerPlantedId ? dangerPlantedId.key : '',
                dangerPlantedName: dangerPlantedId ? dangerPlantedId.label : '',
                dangerPlantedCategoryId: dangerPlantedCategoryId ? dangerPlantedCategoryId.key : '',
                dangerPlantedCategoryName: dangerPlantedCategoryId ? dangerPlantedCategoryId.label : '',
                label: appointmentContactDateTime ? appointmentContactDateTime['subsection'] : '',
                appointmentContactDateTime: appointmentContactDateTime ? appointmentContactDateTime['date'].format('YYYY-MM-DD') : '',
                name: form.name,
                mobile,
                provinceCode,
                cityCode,
                areaCode,
                phoneCode
            }
            return filterParams(params)
        },
        /**
     * 获取险种
     */
        async getInsuranceList() {
            try {
                const data = await getInsuranceListService()
                this.insuranceList = data
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        /**
     * 获取来源id
     */
        async getSourceId() {
            const obj = await getSourceIdService({
                name: '工保网'
            }) || []
            this.potentialCustomerSourceId = obj[0] && obj[0].id || ''
        },
        /**
     * 预约
     */
        async createBooking(data) {
            if (!this.passCode) {
                this.$message.error(this.errorMsg)
                return false
            }
            try {
                this.loading = true
                this.$emit('close')
                this.isSuccess = false
                await createBookingService(data)
                this.$message.success('预约成功')
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
                this.$emit('close')
                this.isSuccess = true
            }
        },
        /**
     * 验证码验证成功
     */
        handleSuccess(val) {
            if (val) {
                this.passCode = false
                this.errorMsg = val
            } else {
                this.passCode = true
            }
        }
    },
    async asyncData() {
    // 获取咨询来源id
        const obj = await getSourceIdService({
            name: '工保网'
        }) || []
        return {
            potentialCustomerSourceId: obj[0] && obj[0].id || ''
        }
    }
}
