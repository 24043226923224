//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Input as AInput, Button as AButton } from 'ant-design-vue'

import { SMS_COUNTDOWN_TIME } from '@/config'

// 引入接口请求
import potentialSendSmsService from '@/service/servicePanel/potentialSendSmsService'
import potentialValidationSmsService from '@/service/servicePanel/potentialValidationSmsService'

export default {
    name: 'ServicePanelSendVerificationCode',
    components: {
        AInput,
        AButton
    },
    props: {
        value: {
            type: [String, Number]
        },
        phone: {
            type: [String, Number],
            required: true
        },
        isSuccess: {
            type: Boolean,
            default: false
        },
        maxLength: {
            type: [String, Number],
            default: 6
        }
    },
    data() {
        return {
            isSend: false,
            second: SMS_COUNTDOWN_TIME,
            loading: false
        }
    },
    computed: {
        codeValue: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
        getButtonText() {
            const { loading, isSend, second } = this
            return loading ? '加载中' : isSend ? `重发${second}s` : '发送'
        }
    },
    watch: {
        isSuccess: {
            handler(val) {
                this.isSend = false
            },
            immediate: true
        },
        phone: {
            handler(val) {
                this.isSend = false
                this.$emit('input', '')
            },
            immediate: true
        }
    },
    methods: {
    /**
     * 发送短信验证码
     */
        async sendMessage() {
            this.loading = true
            try {
                this.sendButtonDisabled = true
                const { phone } = this
                if (!phone) {
                    this.$message.error('手机号不能为空')
                    return
                }
                const exp = /^1[3456789][0-9]{9}$/.test(phone)
                if (!exp) {
                    this.$message.error('请填写正确的手机号')
                    return
                }
                await potentialSendSmsService({
                    phone
                })
                this.isSend = true
                this.countdown()
            } catch (e) {
                this.sendButtonDisabled = false
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        },

        /**
     * 短信验证码倒计时
     */
        countdown() {
            this.second = SMS_COUNTDOWN_TIME
            this.interval = setInterval(() => {
                const { second } = this
                if (second) {
                    this.second = second - 1
                } else {
                    clearInterval(this.interval)
                    this.isSend = false
                }
            }, 1000)
        },

        /**
     * 验证短信验证码
     */
        async verificationCode() {
            const { phone } = this
            const { codeValue } = this
            try {
                await potentialValidationSmsService({
                    phone,
                    code: codeValue
                })
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
                this.$emit('success', e.message)
            }
        },
        handleBlur(e) {
            this.$emit('blur', e)
            this.verificationCode()
        }
    }
}
