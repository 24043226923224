//
//
//
//
//
//
//
//
//

// 组件
import ServicePanelInModal from '@/weights/ServicePanelInModal'

// 全局数据
import { PRODUCT_CONSULTING } from '@/constant/enum'

export default {
    name: 'FormPanel',
    components: {
        ServicePanelInModal
    },
    props: {
        mode: {
            type: String,
            default() {
                return ''
            }
        },
        data: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            PRODUCT_CONSULTING,
            productInfo: {}
        }
    },
    computed: {
        dataChange() {
            const { mode, data } = this
            return {
                mode,
                data
            }
        },
        userInfo() {
            return this.$store.state.userInfo
        }
    },
    watch: {
        dataChange: {
            handler(val) {
                const { data } = val
                const { id, dangerPlantedCategoryId, dangerPlantedId, dangerPlantedCategoryName, dangerPlantedName } = data
                this.productInfo = {
                    spuId: id,
                    dangerPlantedCategoryId,
                    dangerPlantedId,
                    dangerPlantedCategoryName,
                    dangerPlantedName
                }
            },
            immediate: true
        }
    },
    mounted() {
    },
    methods: {
    /**
     * 关闭弹框
     */
        handleCancel() {
            this.$emit('success')
        }
    }
}
