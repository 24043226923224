
import { Button as AButton } from 'ant-design-vue'
export default {
    name: 'CustomButton',
    components: {
        AButton
    },
    props: {
        // 类别
        type: {
            type: String,
            required: false,
            default: 'default'
        },
        // 行为
        act: {
            type: String,
            required: false,
            default: ''
        },
        // 尺寸
        size: {
            type: String,
            required: false,
            default: 'default'
        },
        // 文本
        text: {
            type: String,
            required: true,
            default: ''
        },
        // 禁用
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        // loading
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        className() {
            const { type } = this
            return `custom-button custom-button__${type}`
        },
        props() {
            const { $props } = this
            const { ...props } = $props
            return props
        },
        listeners() {
            return {
                ...this.$listeners
                // 点击事件
                // click: (event) => {
                //     this.$emit('click', event, this.act)
                // }

            }
        }
    },
    methods: {},
    render(h) {
        return h('AButton', {
            class: this.className,
            on: this.listeners,
            props: this.props,
            scopedSlots: this.scopedSlots || this.$scopedSlots
        }, [this.$slots.expansion, this.text])
    }
}
