//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    DatePicker as ADatePicker,
    Icon as AIcon,
    Radio as ARadio
} from 'ant-design-vue'
import moment from 'moment'
export default {
    name: 'HalfDayDatePicker',
    components: {
        ADatePicker,
        AIcon,
        ARadio,
        ARadioGroup: ARadio.Group,
        ARadioButton: ARadio.Button
    },
    props: {
        value: {
            type: Object,
            default() {
                return {
                    date: null,
                    subsection: 0
                }
            }
        },

        dateFormat: {
            type: String,
            default: 'YYYY/M/D'
        }
    },
    data() {
        return {
            open: false,
            disabledTime: false
        }
    },
    computed: {
        getValue: {
            get() {
                const { value } = this
                if (value && value.date) {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.disabledTime = moment().get('date') === value.date.get('date') && moment().valueOf() > moment(12, 'HH').valueOf() ? true : false
                }
                return value
            },
            set(val) {
                this.$emit('input', {
                    date: val.date,
                    subsection: val.subsection
                })
            }
        }
    },
    watch: {
        value: {
            handler(newValue) {
                let { subsection } = newValue
                // 根据subsection判断是上午还是下午，手动设置时分秒，方便format (M/D a)判断
                // 如果默认不选时分秒，会获取系统时间，然后判断上午/下午
                if (subsection) {
                    // console.log('下午', subsection)
                    this.getValue.date.hours(13) // 设置13点，当做下午
                } else {
                    // console.log('上午', subsection)
                    this.getValue.date.hours(9) // 设置9点，当做上午
                }
            },
            immediate: true
        }
    },
    mounted() {

    },
    methods: {
        disabledDate(current) {
            return current && current < moment().endOf('day') - 24 * 60 * 60 * 1000 || current && current > moment().endOf('day') + 48 * 60 * 60 * 1000
        },
        handleRadio(val) {
            if (val === this.getValue.subsection) {
                this.setPanel(false)
            }
        },
        handleRadioChange(val) {
            const { target } = val
            const { getValue } = this
            const subsection = target.value

            this.$emit('input', {
                ...getValue,
                subsection
            })
            this.$nextTick(() => {
                this.setPanel()
            })
        },
        handleDateChange(val) {
            if (!val) {
                // 清空日期需要清空单选框
                this.handleRadioChange({
                    target: {
                        value: 0
                    }
                })
                return
            }
            this.disabledTime = moment().get('date') === val.get('date') && moment().valueOf() > moment(12, 'HH').valueOf() ? true : false
            this.$emit('input', this.getValue)
            this.setPanel(true)
        },
        setPanel(status) {
            if (status !== undefined) {
                this.open = status
                return
            }
            const { getValue } = this
            const { date, subsection } = getValue
            if (date && (subsection || subsection === 0)) {
                this.open = false
            }
        },
        handleOpenChange(status) {
            this.open = status
        },
        handleBlur() {
            this.$emit('blur')
        }
    }
}
