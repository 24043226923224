//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 组件
import CommonTitle from '@/components/CommonTitle'
export default {
    name: 'Partner',

    components: {
        CommonTitle
    },
    data() {
        return {
            title: '合作伙伴',
            isExpand: false,
            parentBoxHeight: '240px', // 合作伙伴盒子高度
            list: [
                {
                    icon: require('@/assets/images/home/partner1.jpg')
                },
                {
                    icon: require('@/assets/images/home/partner22x.png')
                },
                {
                    icon: require('@/assets/images/home/partner32x.png')
                },
                {
                    icon: require('@/assets/images/home/partner42x.png')
                },
                {
                    icon: require('@/assets/images/home/partner52x.png')
                },
                {
                    icon: require('@/assets/images/home/partner62x.png')
                },
                {
                    icon: require('@/assets/images/home/partner72x.png')
                },
                {
                    icon: require('@/assets/images/home/partner82x.png')
                },
                {
                    icon: require('@/assets/images/home/partner92x.png')
                },
                {
                    icon: require('@/assets/images/home/partner102x.png')
                },
                {
                    icon: require('@/assets/images/home/partner11.png')
                },
                {
                    icon: require('@/assets/images/home/partner12.png')
                },
                {
                    icon: require('@/assets/images/home/partner13.png')
                },
                {
                    icon: require('@/assets/images/home/partner14.png')
                },
                {
                    icon: require('@/assets/images/home/partner15.png')
                },
                {
                    icon: require('@/assets/images/home/partner16.png')
                },
                // {
                //     icon: require('@/assets/images/home/partner17.png')
                // },
                {
                    icon: require('@/assets/images/home/partner18.png')
                },
                {
                    icon: require('@/assets/images/home/partner19.png')
                },
                {
                    icon: require('@/assets/images/home/partner20.png')
                },
                {
                    icon: require('@/assets/images/home/partner21.png')
                },
                {
                    icon: require('@/assets/images/home/partner22.png')
                },
                {
                    icon: require('@/assets/images/home/partner23.png')
                },
                {
                    icon: require('@/assets/images/home/partner24.png')
                },
                {
                    icon: require('@/assets/images/home/partner25.png')
                },
                {
                    icon: require('@/assets/images/home/partner26.png')
                }
            ]
        }
    },

    watch: {
        isExpand() {
            this.$nextTick(_ => {
                if (this.isExpand) {
                    this.parentBoxHeight = this.$refs.partnerListBox.offsetHeight + 'px'
                }
            })
        }
    }
}
