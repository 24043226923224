//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 组件
import {
    Divider as ADivider
} from 'ant-design-vue'
export default {
    name: 'Data',
    components: {
        ADivider
    },
    data() {
        return {
            list: [
                {
                    data: '120',
                    name: '城市区域',
                    description: '作为全国性执牌机构，业务已覆盖全国120+地市。'
                },
                {
                    data: '30',
                    name: '全国性保险公司',
                    description: '与30+全国性保险公司合作，与500+保险机构业务合作。'
                },
                {
                    data: '20000',
                    name: '建筑企业',
                    description: '累计服务全国20000+建筑企业，切实帮助企业提供保障。'
                },
                {
                    data: '60000',
                    name: '工程项目',
                    description: '为60000+项目提供工程保险产品服务。'
                }
            ]
        }
    }
}
