import { CMS_BASE_URL } from '@/config'

/**
 * 获取内容分类
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_CMS_CLASSIFICATION = {
    url: `${CMS_BASE_URL}/api.php?c=project&f=cate`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 获取数据列表
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_CMS_LIST_DATA = {
    url: `${CMS_BASE_URL}/api.php?c=project`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 获取数据内容
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_CMS_CONTENT_DATA = {
    url: `${CMS_BASE_URL}/api.php?c=content`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
