//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 组件
import { Carousel as ACarousel } from 'ant-design-vue'

// 请求
import getBannerListService from '@/service/banner/getBannerListService'
export default {
    name: 'Banner',
    components: {
        ACarousel
    },
    props: {
        type: {
            type: [String, Number]
        }
    },
    data() {
        return {
            // banner列表
            bannerList: []
        }
    },
    mounted() {
        this.getBannerList()
    },
    methods: {
        // 获取banner列表
        async getBannerList() {
            const _params = {
                current: 1,
                size: 9999,
                type: this.type,
                enable: true
            }
            const { records: bannerList } = await getBannerListService(_params)
            this.bannerList = bannerList || []
        },
        // 获取banner的style样式
        getBannerStyle({ picture, url }) {
            return {
                backgroundImage: `url(${picture})`,
                cursor: url ? 'pointer' : 'default'
            }
        },
        // 处理banner的鼠标点击事件
        handleBannerClick({ url }) {
            url && window.open(url)
        }
    }
}
