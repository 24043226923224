import { BASE_URL } from '@/config'

/** banner
 * ————————————————————————————————————————————————————————————————————————————————————————————*/

/**
 * 轮播图集合查询
 *
 */
export const GET_BANNER_LIST_SELECT = {
    url: `${BASE_URL}/common/bander/select`,
    method: 'get',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
