import { axios } from '@/plugins/axios'
import { GET_PROVINCE_REDIS_LIST } from './api'
import { HTTP_ERR_SUCCESS, HTTP_RESULT_FIELD, HTTP_ERR_MESSAGE_FIELD } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'

let dataRedis = null
/**
 * 获取省市区
 * @param payload
 * @param config
 * @returns {Promise<*>}
 */
export default async function getProvinceRedisService(payload = {}, config = {}) {
    // 系统地区-有缓存
    if (dataRedis) {
        return dataRedis
    }

    // 系统地区-无缓存
    const param = {
        ...GET_PROVINCE_REDIS_LIST,
        ...config,
        params: payload
    }
    const { data } = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        // 系统地区-缓存
        dataRedis = data[HTTP_RESULT_FIELD]
        return dataRedis
    } else {
        throw new Error(data[HTTP_ERR_MESSAGE_FIELD] || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
