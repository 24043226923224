//
//
//
//
//
//
//
//
//
//

export default {
    name: 'CommonTitle',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
