import { BASE_URL } from '@/config'
/** 服务预约相关接口
 * ————————————————————————————————————————————————————————————————————————————————————————————*/
/**
 * 发送验证码
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_POTENTIAL_SEND_SMS = {
    url: `${BASE_URL}/customer/potential-customer/sendSms`,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    // 'token':'33333'
    },
    timeout: 30000
}

/**
 * 验证码校验
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const POTENTIAL_VALIDATION_SMS = {
    url: `${BASE_URL}/customer/potential-customer/validationSms`,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 获取来源id
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_SOURCE_ID = {
    url: `${BASE_URL}/customer/potential-customer-source/select`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

