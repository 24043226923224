//
//
//
//
//
//
//

import { SERVICE_TEL } from '@/config'
export default {
    name: 'KefuPhone',
    data() {
        return {
            serviceTel: SERVICE_TEL
        }
    }
}
