/**
 * 过滤html标签
 * @params  str
 */
export function filterHtml(str) {
    let result = ''
    if (str) {
        result = str.replace(/<.*?>/g, '')
    }
    return result
}
