//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 组件
import {
    Modal as AModal,
    Button as AButton
} from 'ant-design-vue'

export default {
    name: 'CustomModal',
    components: {
        AModal,
        AButton
    },
    props: {
        customClassName: {
            type: String,
            default: ''
        },
        // 点击蒙层是否允许关闭
        maskClosable: {
            type: Boolean,
            default: false
        },
        closable: {
            type: Boolean,
            default: true
        },
        visible: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        },
        width: {
            type: [Number, String],
            default: 520
        },
        resetButtonText: {
            type: String,
            default: '重置'
        },
        submitButtonText: {
            type: String,
            default: '提交'
        },
        footer: {}
    },
    data() {
        return {}
    },
    methods: {
        /**
             * 提交按钮事件
             */
        handleOk() {
            this.$emit('ok')
        },
        /**
             * 重置按钮事件
             */
        handleReset() {
            this.$emit('reset')
        },
        /**
             * 关闭弹框
             */
        handleCancel() {
            this.$emit('close')
        }
    }
}
