//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 接口请求
import getInsuranceListService from '@/service/product/getInsuranceListService'
export default {
    name: 'NavigationBar',
    data() {
        return {
            // 是否展开险种栏
            isExpand: false,

            // 险种list
            productDangerPlantedList: []
        }
    },
    fetchOnServer: true,
    watch: {
        $route: {
            handler(route) {
                // 首页 和 保险方案时，默认展开
                this.isExpand = this.isHomeOrQuote(route)
            },
            immediate: true
        }
    },
    methods: {
        expandNavigationBar() {
            // 展开 navigation bar
            this.isExpand = true
        },
        collapseNavigationBar() {
            // 收拢 navigation bar
            if (this.isHomeOrQuote(this.$route)) {
                // 首页 和 保险方案，固定展开，不让收拢
                return
            }
            this.isExpand = false
        },
        handleJump(dangerPlantedId) {
            // 处理路由跳转，路由跳转至 保险产品 页面
            this.$router.push({
                name: 'product',
                query: {
                    dangerPlantedId
                }
            })
        },
        isHomeOrQuote({ name }) {
            // 判断当前页面是否为 首页 或 保险方案
            return ['index', 'quote'].indexOf(name) > -1
        }
    },
    async fetch() {
        const _params = {
            recommended: true,
            dangerPlantedCategoryId: process.env.VUE_APP_HOME_DANGER_PLANTED_CATEGORY_ID
        }
        const list = await getInsuranceListService(_params) || []
        this.productDangerPlantedList = list.slice(0, 6)
    }
}
