//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Feature',
    components: {},
    data() {
        return {
            list: [
                {
                    icon: require('@/assets/images/home/feature12x.png'),
                    name: '垂直专业'
                },
                {
                    icon: require('@/assets/images/home/feature22x.png'),
                    name: '智能高效'
                },
                {
                    icon: require('@/assets/images/home/feature32x.png'),
                    name: '管家服务'
                },
                {
                    icon: require('@/assets/images/home/feature42x.png'),
                    name: '保障全面'
                }
            ]
        }
    }
}
