//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 组件
import {
    List as AList,
    Card as ACard,
    Avatar as AAvatar,
    Icon as AIcon,
    Button as AButton
} from 'ant-design-vue'
import CommonTitle from '@/components/CommonTitle'

// 全局数据
import { CMS_BASE_URL } from '@/config'

// 工具类
import { filterHtml } from '@/utils/filterHtml'
import moment from 'moment'

// 请求
import getCMSClassificationService from '@/service/cms/getCMSClassificationService'
import getCMSListDataService from '@/service/cms/getCMSListDataService'
export default {
    name: 'News',
    components: {
        CommonTitle,
        AList,
        ACard,
        AAvatar,
        AIcon,
        AButton,
        AListItem: AList.Item,
        AListItemMeta: AList.Item.Meta,
        ACardMeta: ACard.Meta
    },
    data() {
        return {
            CMS_BASE_URL,
            pageRs: {},
            sublist: [],
            insuranceArr: [],
            insuranceObj: {},
            hotArr: [],
            url: '',
            loading: false
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        moment,
        init() {
        // 获取新闻资讯
            this.getCMSClassification()
            // 获取列表
            this.getCMSListData()
        },
        /**
       * 获取新闻资讯
       */
        async getCMSClassification() {
            try {
                this.loading = true
                // 获取内容
                const { page_rs, subcate_rs } = await getCMSClassificationService({
                    id: 'news'
                })

                // 1.title
                this.pageRs = page_rs || {}

                // 2. sublist
                let list = []
                const { all: sublist } = subcate_rs || {}
                for (let k in sublist) {
                    const item = sublist[k]
                    let obj = {
                        id: item.id,
                        title: item.title,
                        url: item.url
                    }
                    // if (item.id === '68') {
                    //   this.url = item.url
                    // }
                    list.push(obj)
                }
                this.sublist = list.sort((a, b) => a.taxis - b.taxis)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        /**
       * 获取列表
       */
        async getCMSListData() {
            try {
                this.loading = true
                // 获取内容
                const data = await getCMSListDataService({
                    id: 'news'
                })
                let { rslist } = data
                this.insuranceObj = rslist[0]
                this.insuranceObj['_bgColor'] = this.setClassifyBg(this.insuranceObj.cate.title) // 获取背景色,设置背景色,鼠标移入，标签背景颜色需要根据类型显示不同颜色

                this.insuranceArr = rslist.slice(1, 4)

                this.insuranceArr = this.insuranceArr.map(item => {
                    item['_bgColor'] = this.setClassifyBg(item.cate.title)
                    return item
                }) // 获取背景色,设置背景色,鼠标移入，标签背景颜色需要根据类型显示不同颜色
                this.hotArr = rslist.slice(4, 11)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        /**
       * 页面跳转
       */
        handleToDetail(item) {
        // window.open(url)
            let routeData = this.$router.resolve({
                name: 'news-detail',
                query: {
                    id: item.id
                }
            })
            window.open(routeData.href, '_blank')
        },
        /**
       * 跳转到新闻资讯列表
       */
        handleToNews() {
            this.$router.push({
                name: 'news-list'
            })
        },
        /**
       * 跳转到新闻资讯列表 对应 tab
       */
        handleToNewTab(item) {
            this.$router.push({
                name: 'news-list',
                // params: {
                //     tab: item.cate.cate_id       //分类id，新闻资讯tab的value是分类id
                // }
                params: {
                    tab: item.id // 分类id，新闻资讯tab的value是分类id
                }
            })
        },
        /**
       * 简介-过滤掉html标签
       */
        handleFilterHtml(str) {
            const r = filterHtml(str)
            return r
        },
        /**
       * 列表页
       */
        handleToList(item) {
            let path = {
                name: 'news-list',
                query: {}
            }
            let routeData = this.$router.resolve(path)
            window.open(routeData.href, '_blank')
        },

        // 获取分类Tag背景颜色
        setClassifyBg(key) {
            if (!key) return

            let tagMap = {
                热点资讯: '#C66565',
                保险课堂: '#2B9B99',
                公司动态: '#587CD0',
                最新公告: '#E08E1E'
            }

            return tagMap[key.trim()]
        },

        // tag标签 鼠标移入 获取改变背景颜色
        handleEnterTag($e, color) {
            $e.target.style.cssText = `background: ${color}; border-color: ${color};`
        },

        // tag标签 鼠标移出 恢复背景颜色
        handleLeaveTag($e) {
            $e.target.style.cssText = `background: transparent; border-color: innert;`
        }
    }
}
