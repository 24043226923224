import { axios } from '@/plugins/axios'
import { HTTP_CMS_ERR_SUCCESS } from '@/constant/api_status'
import { GET_CMS_CLASSIFICATION } from './api'
import { encrypt } from './utils'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'
// import { isLowObjectChange } from '@/utils/isLowObjectChange'

const SECRET_KEY = 'mQ4kNE30VQUxTWdU'
// let dataRedis = null                //数据缓存
// let dataRedisParams = {}                //参数缓存

/**
 * 获取内容分类
 * @param payload
 * @param config
 * @returns {Promise<*>}
 */
export default async function getCMSClassificationService(payload = {}, config = {}) {
    // 有缓存 判断 参数 是否一致，不一致 则 重新请求
    // if (dataRedis && !isLowObjectChange(dataRedisParams, payload)) return dataRedis

    const _safecode = encrypt(SECRET_KEY)
    payload = {
        ...payload,
        _safecode
    }
    const param = {
        ...GET_CMS_CLASSIFICATION,
        ...config,
        params: payload
    }
    const { data } = await axios(param)
    if (data.status === HTTP_CMS_ERR_SUCCESS) {
        // 数据缓存
        // dataRedis = data.info
        // dataRedisParams = payload
        return data.info
    } else {
        throw new Error(data.info || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
