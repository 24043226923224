//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 组件
import { Button as AButton } from 'ant-design-vue'
import CommonTitle from '@/components/CommonTitle'

// 请求
import getInsuranceListService from '@/service/product/getInsuranceListService'
export default {
    name: 'Insurance',
    components: {
        AButton,
        CommonTitle
    },
    props: {
        insuranceList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            title: '工保精选',
            list: [],
            // listItem: {},
            defaultBg: require('@/assets/images/home/insurance_bg2x.png'),
            compareBg: require('@/assets/images/home/insurance_bg_2.png')
        }
    },
    created() {
        this.getList()
    },
    methods: {
        // 获取险种
        async getList() {
            try {
                const result = await getInsuranceListService({
                    recommended: true
                })

                if (result && result.length > 0) {
                    let list = []
                    if (result.length > 6) {
                        list = result.slice(0, 6)
                    } else {
                        list = result.slice(0, result.length)
                    }
                    this.list = list.map(item => {
                        item.hover = `
                            background: url(${item.picture}) rgb(255, 255, 255) no-repeat center center;
                            background-size: 100%;
                        `
                        return item
                    })
                }
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        // 移入移出
        handleMouse(item, isOut) {
            const { defaultBg } = this
            if (isOut) {
                // 移出
                item.hover = `
                    background: url(${item.picture}) rgb(255, 255, 255) no-repeat center center;
                    background-size: 100%;
                `
            } else {
                // 移入
                item.hover = `background-image: url(${item.picture}), url(${defaultBg})`
            }
        },
        // 根据险种查询产品
        handleToProduct({ id: dangerPlantedId }) {
            this.$router.push({
                name: 'product',
                query: {
                    dangerPlantedId
                }
            })
        }
    }
}
