//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import KefuPhone from './kefuPhone'
import { BackTop } from 'ant-design-vue'
import BookingModal from '@/weights/Product/ProductInfo/ProductInfo/ProductForm/BookingModal'
import { QI_YU } from '@/config'

export default {
    name: 'RightSide',
    components: {
        KefuPhone,
        ABackTop: BackTop,
        BookingModal
    },
    data() {
        return {
            activeIndex: -1,
            // 弹框-预约咨询-相关参数
            mode: '',
            visible: false,
            currentRow: {},
            QI_YU // 七鱼地址
        }
    },
    mounted() {
        (function (w, d, n, a, j) {
            w[n] = w[n] || function () {
                return (w[n].a = w[n].a || []).push(arguments)
            }
            j = d.createElement('script')
            j.async = true
            j.src = QI_YU
            d.body.appendChild(j)
        }(window, document, 'ysf'))
        // npm 写法备用 npm uninstall @neysf/qiyu-web-sdk --save
        // YSF.init('0b89a5c668263ab092c4b96d0bebe2a1', {
        //     hidden: 0
        // }).then(ysf => {
        //     window.ysf = ysf
        // }).catch(error => {
        //     console.log('sdk加载失败---', error)
        // })
    },
    methods: {
        handleShowService(index) {
            this.activeIndex = index
        },
        handleHideService() {
            this.activeIndex = -1
        },
        /**
     * 预约咨询-弹框
     */
        handleBooking() {
            this.mode = 'booking'
            this.visible = true
        },
        /**
     * 预约咨询-弹框关闭
     */
        handleModalClose() {
            this.visible = false
        },
        handleModalSuccess() {
            this.handleModalClose()
        },
        handleKefu() {
            // qimoChatClick()
            window.ysf('open')
        }
    }
}
